import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ListenerCourses from '../ListenerCourses/ListenerCourses';
import ListenerCerts from '../ListenerCerts/ListenerCerts';
import AuthContext from '../AuthContext/AuthContext';

function ListenerDetails() {
    let { userid } = useParams();
    const { platformData, fetchPlatform } = useContext(AuthContext);
    const [listenerDetails, setListenerDetails] = useState([]);
    const [userCompletions, setUserCompletions] = useState([]);
    const [userInfo, setUserInfo] = useState(null);
    const [issuingStatus, setIssuingStatus] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (platformData.length == 0) {
                    await fetchPlatform();
                }
                setUserCompletions(platformData.filter((item) => item.userid == userid));

                var userInfo = platformData.filter((item) => {
                    if (item.userid == userid) {
                        return true;
                    } else {
                        return false;
                    }
                });
                if (userInfo.length > 0) {
                    userInfo[0]?.userInfo?.customFields.map((item) => {
                        switch (item.fieldname) {
                            case "iin":
                                userInfo[0].iin = item.fieldvalue;
                                break;
                        }
                    });

                    setUserInfo(userInfo[0]);
                }
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [platformData]);

    return (
        <div className="pt-3">
        <h2>Детали слушателя</h2>
            <div className="mt-4 w-50">
                <ul className="list-group">
                    <li className="list-group-item"><p>ФИО: {userInfo?.userInfo?.lastname + " " + userInfo?.userInfo?.firstname + " " + userInfo?.userInfo?.middlename}</p></li>
                    <li className="list-group-item"><p>ИИН: {userInfo?.iin ? userInfo?.iin : ""}</p></li>
                    {/* <li className="list-group-item"><p>ФИЛИАЛ: {userInfo?.userid}</p></li> */}
                    <li className="list-group-item"><p>Идентификатор в платформе: {userInfo?.userid}</p></li>
                </ul>
            </div>
            <div className="pt-5">
                <h3>Курсы слушателя</h3>
                <ListenerCourses courses={userCompletions} issuingStatus={issuingStatus} setIssuingStatus={setIssuingStatus} />
            </div>
            <div className="pt-5">
                <h3>Выпущенные свидетельства</h3>
                <ListenerCerts userid={userid} issuingStatus={issuingStatus} setIssuingStatus={setIssuingStatus} />
            </div>
        </div>
    );
}

export default ListenerDetails;