import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/ru';
import './CertModalChange.css';

function CertModalChange({ show, data, onClose, issuingStatus, setIssuingStatus }) {

    const [docNum, setDocNum] = useState("");
    const [certId, setCertId] = useState("");
    const [fullName, setFullName] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [fromDateKZ, setFromDateKZ] = useState("");
    const [toDate, setToDate] = useState("");
    const [toDateKZ, setToDateKZ] = useState("");
    const [issueDate, setIssueDate] = useState("");
    const [issueDateKZ, setIssueDateKZ] = useState("");
    const [type, setType] = useState("");
    const [typeKZ, setTypeKZ] = useState("");
    const [specialty, setSpecialty] = useState("");
    const [specialtyKZ, setSpecialtyKZ] = useState("");
    const [cycle, setCycle] = useState("");
    const [cycleKZ, setCycleKZ] = useState("");
    const [hours, setHours] = useState("");
    const [credits, setCredits] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [studentId, setStudentId] = useState("");
    const [courseId, setCourseId] = useState("");
    const [selectedTheme, setSelectedTheme] = useState("");
    const [phoneRma, setPhoneRma] = useState("");
    const [docId, setDocId] = useState("");
    const [transcriptFields, setTranscriptFields] = useState([]);

    const [showTranscript, setShowTranscript] = useState(false);

    useEffect(() => {

        async function fetchData() {

            try {
                var xdata = data;

                var prefix = localStorage.getItem('prefix');
                var lastId = await getLastId();

                if (lastId == 0) {
                    lastId = 1;
                } else {
                    lastId++;
                }

                if (xdata) {
                    setCertId(xdata.Id);
                    setFromDate(xdata.fromDate);
                    setToDate(xdata.toDate);
                    setFromDateKZ(xdata.fromDateKZ);
                    setToDateKZ(xdata.toDateKZ);
                    setDocNum(xdata.DocumentNumber);
                    setFullName(xdata.StudentName);
                    setType(xdata.TypeName);
                    setTypeKZ(xdata.TypeNameKZ);
                    setSpecialty(xdata.SpecialtyName);
                    setSpecialtyKZ(xdata.SpecialtyNameKZ);
                    setIssueDate(xdata.issueDate);
                    setIssueDateKZ(xdata.issueDateKZ);
                    setPhoneNumber(xdata.phoneNumber);
                    //setPhoneRma(xdata.phoneRma);
                    setCycle(xdata.CourseName);
                    setCycleKZ(xdata.CourseNameKZ);
                    //setStudentId(xdata.userid);
                    //setCourseId(xdata.course);
                    setHours(xdata.Hours);
                    setCredits(xdata.credits);
                    // var n = new Date(xdata.enrol_timeend * 1000); // по просьбе филиалов, в группе whatsapp обсуждалось, возражений не было.
                    // setIssueDate(() => moment(n).format('DD-MM-YYYY'));

                    // if (xdata.type == 'Сертификационный курс') {
                    //     setShowTranscript(true);
                    // }
                }
            } catch (error) {
                alert(error);
            }
        }

        fetchData();
    }, [data]);

    const loadTranscriptThemes = async () => {

        // if (showTranscript && specialty && specialtyKZ) {

        //     try {

                // const jwttoken = localStorage.getItem("token");

                // if (!jwttoken) {
                //     console.error("JWT TOKEN IS NOT AVAILABLE");
                //     return;
                // }

                // const headers = {
                //     'Authorization': `Bearer ${jwttoken}`,
                //     'Content-Type': 'application/json'
                // }

                // const payload = {
                //     specialtyRU: cycle,
                //     specialtyKZ: cycleKZ
                // }

                //const response = await axios.post('https://rmacerts.ripov-edu.kz/api/getTranscriptThemes', payload, { headers });

                // if (response.status == 200) {

                //     var data = response.data;
                //     var newFields = [];

                //     for (var i = 0; i < data.length; i++) {

                //         var eachData = data[i];

                //         var themesArrRu = eachData.ThemeNameRU.split(';');
                //         var themesArrKz = eachData.ThemeNameKZ.split(';');

                //         if (themesArrRu.length == themesArrKz.length) {

                //             for (var j = 0; j < themesArrKz.length; j++) {

                //                 const field = {
                //                     name: themesArrRu[j],
                //                     credit: "",
                //                     grade: "",
                //                     nameKZ: themesArrKz[j],
                //                     creditKZ: "",
                //                     gradeKZ: ""
                //                 };

                //                 newFields.push(field);

                //             }

                //         }
                //     }

                //     //setTranscriptFields(transcriptFields => [...transcriptFields, ...newFields]);
                // }
            // }
            // catch (err) {
            //     console.log(err);
            // }

        // }
    }
    // useEffect(() => {

    //     if (showTranscript == true)
    //         loadTranscriptThemes();

    // }, [showTranscript]);   

    if (!show) return null;

    const modalClass = show ? "modal fade show d-block" : "modal fade";

    const closeModal = () => {

        setTranscriptFields([]);
        onClose(setShowTranscript);
        
    };
    const handleIssueCertificate = async (event) => {

        event.preventDefault();

        if (!docNum || !fullName || !fromDate || !toDate || !type || !typeKZ || !specialty || !specialtyKZ || !cycle || !cycleKZ || !hours || !issueDate) {
            alert("Все поля должны быть заполнены.");
            return;
        }

        moment.locale('ru');
        // var formattedFromDate = formatDate(fromDate, 0);
        // var formattedToDate = formatDate(toDate, 1);
        // var formattedIssueDate = formatDate(issueDate, 2);

        const payload = {
            Id: certId,
            docNum: docNum,
            FullName: fullName,
            fromDate: fromDate,
            fromDateKZ: fromDateKZ,
            toDate: toDate,
            toDateKZ: toDateKZ,
            certType: type,
            certTypeKZ: typeKZ,
            Specialty: specialty,
            SpecialtyKZ: specialtyKZ,
            Cycle: cycle,
            CycleKZ: cycleKZ,
            hours: hours,
            credits: credits,
            issueDate: issueDate,
            issueDateKZ: issueDateKZ,
            phoneNumber: phoneNumber
            // StudentId: studentId,
            // CourseId: courseId,
            // BranchId: localStorage.getItem("branchId"),
            // selectedTheme: selectedTheme
        };

        // if (showTranscript) {
        //     payload.IsTranscript = showTranscript;
        //     payload.themes = transcriptFields;
        // }

        try {
            const jwttoken = localStorage.getItem("token");
            if (!jwttoken) {
                console.error("JWT TOKEN IS NOT AVAILABLE");
                return;
            }
            const headers = {
                'Authorization': `Bearer ${jwttoken}`,
                'Content-Type': 'application/json'
            }
            setIssuingStatus(true);
            const response = await axios.post('https://rmacerts.ripov-edu.kz/api/update-cert', payload, { headers });
            console.log(response.data);
            if (response.status >= 200 && response.status < 300 && response.data !== null && response.data.message != null) {
                alert(response.data.message);
                closeModal()
            }
            setTranscriptFields([]);
            setIssuingStatus(false);
        } catch (error) {
            setIssuingStatus(false);
            console.error(error);
            if (error && error.response && error.response.data) {
                if (error.response.data.error) {
                    alert(error.response.data.error);
                } else {
                    alert(error.response.data);
                }
            } else {
                alert("Попробуйте перезайти.");
            }
        }
    };

    async function getLastId() {
        try {
            if (localStorage.getItem('role') === 'admin') {
                return 0;
            }
            const jwttoken = localStorage.getItem("token");
            const prefix = localStorage.getItem("prefix");

            if (!jwttoken || !prefix) {
                console.error();
                throw new Error("JWT TOKEN OR PREFIX IS NOT AVAILABLE");
            }
            const headers = {
                'Authorization': `Bearer ${jwttoken}`,
                'Content-Type': 'application/json'
            }
            var payload = {
                prefix: prefix
            }
            const response = await axios.post('https://rmacerts.ripov-edu.kz/api/getLastId', payload, { headers });
            if (response.status >= 200 & response.status < 300) {
                return response.data;
            } else {
                alert("Ошибка получения номера последнего документа, обратитесь к администратору.");
                return 0;
            }
        } catch (error) {
            alert("Выпуск сертификата" + error);
            throw new Error(error);
        }
    }
    const handleThemeChange = (event) => {
      setSelectedTheme(event.target.value);
    };

    return (
        <div className={modalClass}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{data.fullname}</h5>
                    </div>
                    <form onSubmit={handleIssueCertificate}>
                        <div className="modal-body">
                            <label htmlFor="docNum" className="form-label">Номер документа:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="docNum"
                                value={docNum || ''}
                                onChange={(e) => setDocNum(e.target.value)}
                                required
                            /><br />
                            <label htmlFor="FullName" className="form-label">ФИО:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="FullName"
                                value={fullName || ''}
                                onChange={(e) => setFullName(e.target.value)}
                                required
                            /><br />
                            <label htmlFor="fromDate" className="form-label">От даты:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="fromDate"
                                value={fromDate || ''}
                                onChange={(e) => setFromDate(e.target.value)}
                                required
                            /><br />
                            <label htmlFor="fromDateKZ" className="form-label">От даты (каз):</label>
                            <input
                                type="text"
                                className="form-control"
                                id="fromDateKZ"
                                value={fromDateKZ || ''}
                                onChange={(e) => setFromDateKZ(e.target.value)}
                                required
                            /><br />
                            <label htmlFor="toDate" className="form-label">По дате:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="toDate"
                                value={toDate || ''}
                                onChange={(e) => setToDate(e.target.value)}
                                required
                            /><br />
                            <label htmlFor="toDate" className="form-label">По дате (каз):</label>
                            <input
                                type="text"
                                className="form-control"
                                id="toDate"
                                value={toDateKZ || ''}
                                onChange={(e) => setToDateKZ(e.target.value)}
                                required
                            /><br />
                            <label htmlFor="type" className="form-label">Тип:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="type"
                                value={type || ''}
                                onChange={(e) => setType(e.target.value)}
                                required
                            /><br />
                            <label htmlFor="typeKZ" className="form-label">Тип (каз):</label>
                            <input
                                type="text"
                                className="form-control"
                                id="typeKZ"
                                value={typeKZ || ''}
                                onChange={(e) => setTypeKZ(e.target.value)}
                                required
                            /><br />
                            <label htmlFor="specialty" className="form-label">Специальность:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="specialty"
                                value={specialty || ''}
                                onChange={(e) => setSpecialty(e.target.value)}
                                required
                            /><br />
                            <label htmlFor="specialtyKZ" className="form-label">Специальность (каз):</label>
                            <input
                                type="text"
                                className="form-control"
                                id="specialtyKZ"
                                value={specialtyKZ || ''}
                                onChange={(e) => setSpecialtyKZ(e.target.value)}
                                required
                            /><br />
                            <label htmlFor="cycle" className="form-label">Цикл:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="cycle"
                                value={cycle || ''}
                                onChange={(e) => setCycle(e.target.value)}
                                required
                            /><br />
                            <label htmlFor="cycleKZ" className="form-label">Цикл (каз):</label>
                            <input
                                type="text"
                                className="form-control"
                                id="cycleKZ"
                                value={cycleKZ || ''}
                                onChange={(e) => setCycleKZ(e.target.value)}
                                required
                            /><br />
                            <label htmlFor="hours" className="form-label">Часы:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="hours"
                                value={hours || ''}
                                onChange={(e) => setHours(e.target.value)}
                                required
                            /><br />
                            <label htmlFor="credits" className="form-label">Кредиты:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="credits"
                                value={credits || ''}
                                onChange={(e) => setCredits(e.target.value)}
                                required
                            /><br />
                            <label htmlFor="issueDate" className="form-label">Дата выдачи:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="issueDate"
                                value={issueDate || ''}
                                onChange={(e) => setIssueDate(e.target.value)}
                                required
                            /><br />
                            <label htmlFor="issueDateKZ" className="form-label">Дата выдачи (каз):</label>
                            <input
                                type="text"
                                className="form-control"
                                id="issueDateKZ"
                                value={issueDateKZ || ''}
                                onChange={(e) => setIssueDateKZ(e.target.value)}
                                required
                            /><br />
                            <label htmlFor="phoneNumber" className="form-label">Номер телефона:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="phoneNumber"
                                value={phoneNumber || ''}
                                onChange={(e) => {
                                    const input = e.target.value;
                                    if (/^7\d{0,10}$/.test(input) || input === '') {
                                        setPhoneNumber(input);
                                    }
                                }}
                                required
                            /><br />
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success" disabled={issuingStatus}>Сохранить</button>
                            <button type="button" className="btn btn-secondary" disabled={issuingStatus} onClick={closeModal}>Закрыть</button>
                        </div>
                    </form>
                </div>
            </div>
        </div >
    );
}

export default CertModalChange;